import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/Builder.vue"),
      },
      {
        path: "/system/Ht-Tr-parameter",
        name: "Ht-Tr-parameter",
        component: () =>
          import("@/components/page-layouts/Ht-Tr-parameter.vue"),
        children: [
          {
            path: "overview",
            name: "Ht-Tr-parameter-overview",
            component: () =>
              import("@/views/system/Ht-Tr-parameter/Overview.vue"),
          },
          {
            path: "Add",
            name: "Ht-Tr-parameter-Add",
            component: () => import("@/views/system/Ht-Tr-parameter/Add.vue"),
          },
          {
            path: "List",
            name: "Ht-Tr-parameter-List",
            component: () => import("@/views/system/Ht-Tr-parameter/List.vue"),
          },
        ],
      },
      {
        path: "/system/Modal",
        name: "Modal",
        component: () =>
          import("@/components/page-layouts/Ht-Tr-parameter.vue"),
        children: [
          {
            path: "overview",
            name: "Modal-overview",
            component: () =>
              import("@/views/system/Ht-Tr-parameter/Overview.vue"),
          },
          {
            path: "Add",
            name: "Modal-Add",
            component: () => import("@/views/system/Modal/Add.vue"),
          },
          {
            path: "List",
            name: "Modal-List",
            component: () => import("@/views/system/Modal/List.vue"),
          },
        ],
      },
      {
        path: "/system/IQC",
        name: "IQC",
        component: () =>
          import("@/components/page-layouts/Ht-Tr-parameter.vue"),
        children: [
          {
            path: "overview",
            name: "IQC-overview",
            component: () =>
              import("@/views/system/Ht-Tr-parameter/Overview.vue"),
          },
          {
            path: "Add",
            name: "IQC-Add",
            component: () => import("@/views/system/IQC/Add.vue"),
          },
          {
            path: "List",
            name: "IQC-List",
            component: () => import("@/views/system/IQC/List.vue"),
          },
        ],
      },
      {
        path: "/system/Ht-Tr-technology",
        name: "Ht-Tr-technology",
        component: () =>
          import("@/components/page-layouts/Ht-Tr-technology.vue"),
        children: [
          {
            path: "Add",
            name: "Ht-Tr-technology-Add",
            component: () => import("@/views/system/Ht-Tr-technology/Add.vue"),
          },
          {
            path: "projects",
            name: "Ht-Tr-technology-projects",
            component: () =>
              import("@/views/system/Ht-Tr-technology/Projects.vue"),
          },
          {
            path: "List",
            name: "Ht-Tr-technology-List",
            component: () => import("@/views/system/Ht-Tr-technology/List.vue"),
          },
          {
            path: "Supplier",
            name: "Ht-Tr-technology-Supplier",
            component: () =>
              import("@/views/system/Ht-Tr-technology/Supplier.vue"),
          },
        ],
      },
      {
        path: "/system/Production-history",
        name: "Production-history",
        component: () =>
          import("@/components/page-layouts/Production-history.vue"),
        children: [
          {
            path: "Add",
            name: "Production-history-Add",
            component: () =>
              import("@/views/system/Production-history/Add.vue"),
          },
          {
            path: "Detail",
            name: "Production-history-Detail",
            component: () =>
              import("@/views/system/Production-history/Detail.vue"),
          },
          {
            path: "Export",
            name: "Production-history-Export",
            component: () =>
              import("@/views/system/Production-history/Export.vue"),
          },
          {
            path: "list",
            name: "Production-history-list",
            component: () =>
              import("@/views/system/Production-history/List.vue"),
          },
        ],
      },

      {
        path: "/system/Account",
        name: "Account",
        component: () =>
          import("@/components/page-layouts/Production-history.vue"),
        children: [
          {
            path: "Employee",
            name: "Account-Employee",
            component: () => import("@/views/system/Account/Employee.vue"),
          },
          {
            path: "Client",
            name: "Account-Client",
            component: () => import("@/views/system/Account/Client.vue"),
          },
          {
            path: "Import",
            name: "Account-Import",
            component: () => import("@/views/system/Account/Import.vue"),
          },
          {
            path: "Enter",
            name: "Account-Enter",
            component: () => import("@/views/system/Account/Enter.vue"),
          },
        ],
      },
    ],
  },

  {
    path: "/system/ProductionLine",
    name: "ProductionLine",
    redirect: "/system/ProductionLine",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "Machine",
        name: "Machine",
        component: () => import("@/views/system/ProductionLine/Machine.vue"),
      },
      {
        path: "Material",
        name: "Material",
        component: () => import("@/views/system/ProductionLine/Material.vue"),
      },
      {
        path: "CMM",
        name: "CMM",
        component: () => import("@/views/system/ProductionLine/CMM.vue"),
      },
    ],
  },

  //
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/system/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/system/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/system/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/system/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/system/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
